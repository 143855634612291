import React from 'react';
import './App.css';
import Background from "./components/Background/Background";
import Header from "./components/Header/Header";
import Mint from "./components/Mint/Mint";
import {useConnectedAddress} from "./hooks/ConnectedAddress";
import Modal from "./components/Modal/Modal";
import Popup from "./components/Popup/Popup";

function App() {
    const {address} = useConnectedAddress()

    return (
    <div className="App h-screen w-screen back overflow-x-hidden">
        <Header />
        <div
            className="sticky flex flex-col justify-start items-center w-full h-4/5 z-10 pb-20x"
        >
            <Mint />
        </div>
        <Background />
        <Modal />
        <Popup />
    </div>
  );
}

export default App;
