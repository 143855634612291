import React from "react";
import cwButton from "../../assets/Connect-Wallet.png"
import dwButton from "../../assets/Disconnect-Wallet.png"
import {
    useConnectModal,
    useAccountModal
} from '@rainbow-me/rainbowkit';
import {useConnectedAddress} from "../../hooks/ConnectedAddress";
function ConnectButton() {
    const { openConnectModal } = useConnectModal();
    const { openAccountModal } = useAccountModal();
    const {address} = useConnectedAddress()

    function clickWalletConnect() {
        if(address && openAccountModal) {
            openAccountModal();
            return;
        }
        if(openConnectModal) openConnectModal();
    }

    return <button onClick={clickWalletConnect} className="flex h-full md:mx-3" style={{minWidth: "100px"}}>
        <img src={address ? dwButton : cwButton} alt="" className="h-full"/>
    </button>

}

export default ConnectButton;