import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ConnectButtonProvider from "./components/ConnectButton/ConnectButtonProvider";
import {ModalProvider} from "./components/Modal/ModalProvider";
import {PopupProvider} from "./components/Popup/PopupProvider";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ConnectButtonProvider>
        <ModalProvider>
            <PopupProvider>
                <App />
            </PopupProvider>
        </ModalProvider>
    </ConnectButtonProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
