import React, {FC, ReactNode} from "react";

export interface ModalContextType {
    props: ModalProps;
    dispatchModal: React.Dispatch<{props: ModalProps}>
}

export const ModalContext = React.createContext<ModalContextType>({
  props: {open: false, content: <></>},
  dispatchModal: () => {},
});

export interface ModalProps {
    open: boolean;
    content: ReactNode;
    onCloseReload?: boolean;
}

export const ModalReducer = (
  state: ModalProps,
  action: {props: ModalProps}
): ModalProps => {
  return {...action.props};
};

interface MintingProviderProps {
    children?: React.ReactNode
}

export const ModalProvider: FC<MintingProviderProps> = (props) => {

  const [ModalProps, dispatchModal] = React.useReducer(
      ModalReducer,
      {open: false, content: <></>}
  );

  return <ModalContext.Provider
    value={{props: ModalProps, dispatchModal: dispatchModal}}
  >
    {props.children}
  </ModalContext.Provider>;
};