import MinusButton from "../../assets/-.png";
import Wordmark from "../../assets/Wordmark-Logo.png";
import PlusButton from "../../assets/+.png";
import React from "react";
import TransactionStats from "../TransactionStats/TransactionStats";

interface QuantitySelectorProps {
    maxAmount: number,
    isActive: boolean,
    amount: number,
    setAmount: (amount: number) => void
}

function QuantitySelector({maxAmount, isActive, amount, setAmount}: QuantitySelectorProps) {

    const handlePlus = async () => {
        if(!isActive) return;

        if (amount < maxAmount) {
            setAmount(amount+1)
        }
    }

    const handleMinus = () => {
        if(!isActive) return;

        if (amount > 1) {
            setAmount(amount-1)
        }
    }


    return (
        <>
            <div className="flex flex-row items-center justify-center sm:justify-between">
                <button className="m-2 md:m-20 sm:m-10 md:max-w-16 max-w-12" onClick={handleMinus}>
                    <img src={MinusButton} alt="minus" />
                </button>
                <img src={Wordmark} alt="" className="pass max-w-48 md:max-w-md" />
                <button className="m-2 md:m-20 sm:m-10 md:max-w-16 max-w-12" onClick={handlePlus}>
                    <img src={PlusButton} alt="plus" />
                </button>
            </div>
            <TransactionStats amount={amount} />
        </>
    )
}

export default QuantitySelector
