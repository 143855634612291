import {formatEther, Provider, Signer} from "ethers";
import {NFTVenuesHole as Contract, NFTVenuesHole__factory as factory} from "./types";
import {MintingState} from "./MintingState";
import {WhiteList} from "./WhiteList";
import Proofs1 from "../abis/proofs/1.json";

export class Hole  {
    private _contract: Contract;
    private proofs = {
        1: Proofs1
    }


    private static _configs: {[key: number]: string} = {
        31337: "0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0",
        1: "0xc24408a8775c2c50c361cb8d9943f649643c9268"
    }

    static async getContract(signerOrProvider: Signer | Provider): Promise<Hole> {
      let provider: Provider | undefined;
      if(signerOrProvider.provider) {
        provider = signerOrProvider.provider;
      } else {
        provider = signerOrProvider as Provider;
      }

      if(!provider) throw Error("Provider not found");

      const network = await provider.getNetwork();
      return new Hole(this._configs[Number(network.chainId)], signerOrProvider);
    }

    constructor(contractAddress: string, provider: Signer | Provider) {
      this._contract = factory.connect(contractAddress, provider);
    }


    public gas(count: number) {
        // TODO: Check
        switch (true) {
            case Number(count) > 0 && Number(count) <= 1:
                return "200000";
            case Number(count) > 1 && Number(count) <= 6:
                return "300000";
            case Number(count) > 6 && Number(count) <= 10:
                return "400000";
        }
        return 70000 * count;
    };

    public async payedMint(amount: number, address: string) {
        const mintValue = await this._contract.openMintValue();
        return this._contract.mint(amount, {
            from: address,
            value: mintValue * BigInt(amount),
            gasLimit: this.gas(amount)
        });
    }

    public async whiteListMint (amount: number, address: string) {
        const whiteList = await this.getWhiteListOpen();
        const proofs = await this.getProofs(address);

        if(!proofs) return;

        return this._contract.whiteListMint(amount, proofs, {
            from: address,
            value: whiteList.mintValue * BigInt(amount),
            gasLimit: this.gas(amount)
        });
    }

    public async mint(amount:number, address: string) {
        if(await this.isWhiteListOpen()) {
            return await this.whiteListMint(amount, address);
        }
        if(await this.isPayedMintOpen()) {
            return await this.payedMint(amount, address);
        }
    }

    public async isWhiteListOpen() {
        return (await this.getOpenWhitelistId()) !== BigInt(0);
    }

    public async isInWhiteList(address: string) {
        const proofs = await this.getProofs(address);
        return !!proofs;
    }

    public async getOpenWhitelistId(): Promise<bigint> {
        return await this._contract.openWhiteListId();
    }

    public async getProofs(address: string): Promise<string[] | undefined> {
        const openWhiteListId = await this.getOpenWhitelistId();
        if(openWhiteListId !== BigInt(0)) {
            // @ts-ignore
            return this.proofs[openWhiteListId][address.toLowerCase()];
        }
        return undefined;
    }

    public async whiteListCap(address: string) {
        const openWhiteListId = await this.getOpenWhitelistId();
        return Number(await this._contract.whiteListCapTracker(openWhiteListId, address));
    }

    public isPayedMintOpen(): Promise<boolean> {
        return this._contract.isMintOpen();
    }

    public async getSupply() {
        return await this._contract.totalSupply();
    }

    public async getMaxSupply() {
        return await this._contract.maxSupply();
    }

    public async getMintValue() {
        const [whiteList, mintValue] = await Promise.all([this.getWhiteListOpen(), this._contract.openMintValue()])
        // If it's the id return
        return whiteList.mintValue === BigInt(0) ? mintValue : whiteList.mintValue;
    }

    public async getMintValueInEth() {
        const mintValueInWei = await this.getMintValue()
        return parseFloat(formatEther(mintValueInWei))
    }

    public async isSoldOut() {
        const [supply, maxSupply] = await Promise.all([
            this.getSupply(),
            this.getMaxSupply()
        ]);
        return supply >= maxSupply;
    }

    public async getMintingState(): Promise<MintingState> {
        const [isSoldOut, isWhiteListOpen, isPayedMintOpen] = await Promise.all([
            this.isSoldOut(),
            this.isWhiteListOpen(),
            this.isPayedMintOpen(),
        ])

        if(isSoldOut) return MintingState.SOLD_OUT;
        if(isWhiteListOpen) return MintingState.WHITELIST;
        if(isPayedMintOpen) return MintingState.OPEN_MINT;
        return MintingState.NOT_ACTIVE;
    }

    public async getWhiteListOpen(): Promise<WhiteList> {
        const whiteList =  await this._contract.getOpenWhiteList()
        return {
            id: Number(whiteList[0]),
            mintValue: whiteList[1],
            rootHash: whiteList[2],
            cap: Number(whiteList[3]),
        }
    }


}