import React from "react";
import Logo from "../Logo/Logo";
import ConnectButton from "../ConnectButton/ConnectButton";

function Header() {
  return (
    <header className="sticky flex flex-row justify-between p-10 pb-0 z-20">
      <div className="flex items-center m-auto h-3/6 md:h-5/6 w-1/3" >
        <Logo />
      </div>
      <div className="flex md:flex-row-reverse md:m-0 m-auto h-10 items-center md:w-2/3">
        <ConnectButton />
      </div>

    </header>
  );
  
}

export default Header;
