import React, {useEffect, useState} from "react";
import {useHoleContract} from "../../hooks/HoleContract";

interface TransactionStatsProps {
    amount: number
}

const DEFAULT_COST = 0.25

function TransactionStats({amount}: TransactionStatsProps) {
    const holeContract = useHoleContract();
    const [cost, setCost] = useState<number>(DEFAULT_COST);

    useEffect(() => {
        if(!holeContract) return;

        holeContract.getMintValueInEth().then(setCost)
    }, [holeContract]);

    return (
        <>
            <div className="text-5xl font-bold text-white lowercase">
                {amount}
            </div>
            <div className="text-xl text-white uppercase secondary-text leading-tight">
                COST: {cost} ETH + GAS FEES
            </div>
            <div className="text-xl text-white uppercase secondary-text leading-tight mb-3">
                TOTAL COST: {parseFloat((cost * amount).toFixed(4))} ETH + GAS FEES
            </div>
        </>
    )
}

export default TransactionStats