import React, {ReactNode, useEffect, useState} from "react";
import MintingStats from "../MintingStats/MintingStats";
import QuantitySelector from "../QuantitySelector/QuantitySelector";
import MintButton from "../MintButton/MintButton";
import {useHoleContract} from "../../hooks/HoleContract";
import {ModalContext} from "../Modal/ModalProvider";
import {useConnectedAddress} from "../../hooks/ConnectedAddress";
import {MintingState} from "../../contracts/MintingState";

const MAX_AMOUNT_PER_TRANSACTION = 1000;

function ConnectWalletMessage() {
    return (
        <div className="sticky text-white uppercase">
            Connect your wallet to continue
        </div>
    )
}

function Mint() {

    const [canMint, setCanMint] = useState<boolean>(false);
    const [amount, setAmount] = useState<number>(1);
    const [maxAmount, setMaxAmount] = useState<number>(MAX_AMOUNT_PER_TRANSACTION);
    const holeContract = useHoleContract();
    const {address} = useConnectedAddress();
    const {dispatchModal} = React.useContext(ModalContext);


  useEffect(() => {
    if(!holeContract || !address) return;

    (async () => {

      const [mintingState, isInWhiteList, cap] = await Promise.all([
          holeContract.getMintingState(),
          holeContract.isInWhiteList(address),
          holeContract.whiteListCap(address)
      ]);

      let content: ReactNode;
      switch (mintingState) {
          case MintingState.SOLD_OUT:
              content = <>SOLD OUT</>;
              break;
          case MintingState.WHITELIST:
              if(isInWhiteList && cap === 0) {
                  content = <>You can't mint any more Hole Passes, wait for the next phase</>;
              }
              if(!isInWhiteList) {
                  content = <>you are not in the whitelist. <br /> come back later </>
              }
              break;
          case MintingState.NOT_ACTIVE:
              content = <>mint is not ready</>;
      }

      if(content) {
          dispatchModal({props: {open: true, content}});
          setCanMint(false);
          setAmount(1);
          return;
      }

      // If we got here it mean the user can mint
      setCanMint(true);
      if(cap !== 0)
        setMaxAmount(cap);

    })().then(() => console.log("Finish"))
  }, [holeContract, address, dispatchModal]);



    return (
    <>
        {address ? <MintingStats /> : <ConnectWalletMessage />}
        <br className="hidden md:block"/>
        <div className="text-xl text-white uppercase text-center leading-none m-5">
          GET YOUR LUXURY NFT AND <br></br> SEE YOU AT THE BAR
        </div>
        <QuantitySelector maxAmount={maxAmount} amount={amount} setAmount={setAmount} isActive={canMint}/>
        <MintButton isActive={canMint} amount={amount} />
    </>
  )
}

export default Mint;
