import {configureChains, createConfig, mainnet, WagmiConfig} from "wagmi";
import { publicProvider } from 'wagmi/providers/public';
import { infuraProvider } from "wagmi/providers/infura";
import {getDefaultWallets, RainbowKitProvider} from "@rainbow-me/rainbowkit";
import '@rainbow-me/rainbowkit/styles.css';
import React, {ReactNode} from "react";
import {hardhat} from "viem/chains";

const { chains, publicClient } = configureChains(
    [mainnet, hardhat],
    [
        infuraProvider({ apiKey: "86134dc8006341dc824d2fe36c43a948" }),
        publicProvider()
    ]
);

const { connectors } = getDefaultWallets({
    appName: 'My RainbowKit App',
    projectId: "16c0056136fee7edf8e64c3f11919c5e",
    chains
});

const wagmiConfig = createConfig({
    autoConnect: true,
    connectors,
    publicClient
})

function Background({children}: {children: ReactNode}) {
    return <WagmiConfig config={wagmiConfig}>
        <RainbowKitProvider chains={chains}>
            {children}
        </RainbowKitProvider>
    </WagmiConfig>
}

export default Background;