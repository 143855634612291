import {Dialog} from "@mui/material";
import React from "react";
import GoBack from "../../assets/Go-Back.png";
import {ModalContext} from "./ModalProvider";

function Modal() {

    const {props, dispatchModal} = React.useContext(ModalContext);
    const {open, content} = props;

    const closeModal = () => {
        dispatchModal({props: {open: false, content: <></>}})
        if(props.onCloseReload) {
            window.location.reload();
        }
    }


    return <Dialog open={open} onClose={() => console.log("Closed")} PaperProps={{
        style: {
            // backgroundImage: `url("${Board}")`,
            backgroundSize: "100% 100%",
            backgroundColor: 'rgb(0,0,0, 0.6)',
            boxShadow: 'none',
            padding: "2rem",
            borderRadius: "9%"
        },
    }}>
        <div className="flex flex-col items-center">
            <div className="text-5xl text-white uppercase text-center secondary-text">
                {content}
            </div>
            <button onClick={closeModal} className="h-14 mint-button">
                <img src={GoBack} alt="" className="h-full w-auto"/>
            </button>
        </div>
    </Dialog>
}

export default Modal