import {useEffect, useState} from "react";
import {Hole} from "../contracts/Hole";
import {useEthersSigner} from "./EtherSigner";

export function useHoleContract() {
  const signer = useEthersSigner();
  const [holeContract, setHoleContract] = useState<Hole>();

  useEffect(() => {
    if(!signer) return;

    Hole.getContract(signer).then(holeContract => {
      setHoleContract(holeContract);
    });

  }, [signer]);

  return holeContract;

}