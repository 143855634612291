import React from "react";
import BackgroundImage from "../../assets/Background.png"
import BackgroundMobileImage from "../../assets/Background-mobile.png"

function Background() {
  return <picture>
    <source srcSet={BackgroundImage} media="(min-width: 768px)" />
    <img
        src={BackgroundMobileImage}
        className="absolute w-full h-full top-0 left-0 background primary-background z-0"
        alt="Background"
    />
  </picture>
}

export default Background;
