import React from "react";
import logoIcon from "../../assets/Logo.png"

function Logo() {
  return (
      <a href="">
        <img src={logoIcon} alt="NFT Venues Logo" className="h-20 w-auto"/>
      </a>
  );
}

export default Logo;
