import React, {useState} from "react";
import MintButtonImg from "../../assets/Mint-Button.png"
import MintButtonInactiveImg from "../../assets/Mint-Button-Inactive.png"
import Loader from "../../assets/Loader.gif"
import {ModalContext} from "../Modal/ModalProvider";
import {useHoleContract} from "../../hooks/HoleContract";
import {useConnectedAddress} from "../../hooks/ConnectedAddress";
import {PopupContext} from "../Popup/PopupProvider";

interface MintButtonProps {
    isActive: boolean;
    amount: number;
}

function MintButton({isActive, amount}: MintButtonProps) {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const {dispatchModal} = React.useContext(ModalContext);
    const {dispatchPopup} = React.useContext(PopupContext);
    const holeContract = useHoleContract();
    const {address} = useConnectedAddress()
    const mint = async () => {
        if(!isActive || !holeContract || !address || amount === 0) return;

        setIsLoading(true);

        holeContract.mint(amount, address).then(tx => {
            dispatchModal({props: {
                open: true,
                content: <a
                    href={`https://etherscan.io/tx/${tx?.hash}`}
                    target="_blank" rel="noopener noreferrer" className="w-full">
                    Minting in progress
                    <img src={Loader} alt="" className="mint-button loader h-14"/>
                </a>
            }})
            tx?.wait().then(() => {
                dispatchModal({props: {
                        open: true,
                        content: <a
                            href={`https://etherscan.io/tx/${tx?.hash}`}
                            target="_blank" rel="noopener noreferrer" className="w-full">
                            mint successfully
                        </a>,
                        onCloseReload: true,
                }});
                setIsLoading(false);
            })
        }).catch(e => {
            console.error(e);
            dispatchPopup({props: {
                open: true,
                content: <>
                    Ups there was an error sending your transaction. Please try again later
                </>
            }})
            setIsLoading(false);
        })
    }


    if(isLoading)
        return <img src={Loader} alt="" className="mint-button loader h-14"/>


    return (
        <button onClick={mint} className="h-14">
            <img src={isActive || amount === 0 ? MintButtonImg : MintButtonInactiveImg} alt="" className="h-full w-auto"/>
        </button>
    )
}

export default MintButton;