import React, {useEffect, useState} from "react";
import {useHoleContract} from "../../hooks/HoleContract";

function MintingStats() {
    const holeContract = useHoleContract();
    const [supply, setSupply] = useState<bigint | undefined>();

    useEffect(() => {
        if(!holeContract) return;

        holeContract.getSupply().then(setSupply);
    }, [holeContract]);

    return (
        <div className="text-3xl md:text-2xl mt-10 text-white lowercase text-center leading-none">
            {supply?.toString() ?? "-" }/1000 Minted
        </div>
    )
}

export default MintingStats
